import { pathToRegexp } from "path-to-regexp";
import Cookie from "../enums/cookies";

const getAccessTokenAndType = (cookies, fragment) => {
  let [token, type] = ["", ""];
  if (cookies) {
    [token, type] = [
      cookies.get(Cookie.ACCESS_TOKEN),
      cookies.get(Cookie.TOKEN_TYPE),
    ];
  }
  if (!token || !type) {
    [token, type] = getAccessTokenAndTypeFromFragment(fragment);
    cookies.set(Cookie.ACCESS_TOKEN, token, "1h");
    cookies.set(Cookie.TOKEN_TYPE, type, "1h");
  }

  return [token, type];
};

function getAccessTokenAndTypeFromFragment(fragment) {
  if (!fragment) return ["", ""];

  const regex = pathToRegexp(
    "token_type=:type&access_token=:token&expires_in:extra"
  );
  const parsedFragment = regex.exec(fragment);
  if (parsedFragment) {
    return [parsedFragment[2], parsedFragment[1]];
  }

  return ["", ""];
}

export default getAccessTokenAndType;
