<template>
  <div id="app" :class="appClasses.join(' ')">
    <BasicNavbar
      :userData="userData"
      @logOut="resetUserData"
      @goToBilling="toggleBillingLoader"
    />
    <router-view :userData="userData" />
    <div v-if="isLoadingBilling">
      <div class="modal is-active">
        <div class="modal-background"></div>
        <ClipLoader color="#ffffff" :size="50" />
      </div>
    </div>
    <a
      href="https://bulma.io"
      style="position: fixed; bottom: 5px; right: 10px"
    >
      <img
        src="https://bulma.io/images/made-with-bulma.png"
        alt="Made with Bulma"
        width="128"
        height="24"
      />
    </a>
  </div>
</template>

<script>
import BasicNavbar from "./components/BasicNavbar.vue";
import { getCurrentUser } from "./apis/discord";
import getAccessTokenAndType from "./helpers/accessToken";
import Cookie from "./enums/cookies";
import { ClipLoader } from "@saeris/vue-spinners";
import {isSubscribed} from "./apis/chargebee";

export default {
  name: "App",
  components: {
    BasicNavbar,
    ClipLoader,
  },
  data() {
    return {
      userData: null,
      isConfigRoute: false,
      appClasses: ["", ""],
      isLoadingBilling: false,
      bodyClassName: "",
    };
  },
  methods: {
    async getUserData() {
      let [token, type] = getAccessTokenAndType(
        this.$cookies,
        this.$route.params.fragment
      );

      const user = await getCurrentUser(token, type);
      if (user) {
        this.$cookies.set(Cookie.USER_ID, user.id, "1h");
        user.isSubscribed = await isSubscribed(user.id);
      }

      return user;
    },
    resetUserData() {
      this.userData = null;
      this.$cookies.remove(Cookie.USER_ID);
    },
    toggleBillingLoader() {
      this.isLoadingBilling = !this.isLoadingBilling;
      if (this.isLoadingBilling) {
        this.appClasses[EAppClass.MODAL_STATUS] = "modal-open";
      } else {
        this.appClasses[EAppClass.MODAL_STATUS] = "modal-closed";
      }
    },
  },
  async created() {
    this.userData = await this.getUserData();
  },
  async beforeUpdate() {
    this.isConfigRoute = this.$route.name === "Server Config Form";
    switch (this.$route.name) {
      default:
        this.appClasses[EAppClass.ROUTE] = [this.$route.name];
        break;
    }
    if (this.userData) return;
    this.userData = await this.getUserData();
  },
};

const EAppClass = {
  ROUTE: 0,
  MODAL_STATUS: 1,
};
</script>


<style>
@import "../node_modules/bulma/css/bulma.css";
@import "~@creativebulma/bulma-divider/dist/bulma-divider.min.css";
@import "style/route.css";

.logo {
  width: 350px;
  height: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40rem;
}
h1,
h2,
h3 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 5px;
}
#app {
  padding: 10vh 15vw;
}
body,
html {
  height: 100%;
}
.centered {
  margin-left: auto;
  margin-right: auto;
}
#app.modal-open {
  overflow: hidden;
  position: fixed;
}
</style>