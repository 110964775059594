const sessionDefaults = {
    'pomodoro': 25,
    'shortBreak': 5,
    'longBreak': 15,
    'intervals': 4
};

const reminderAlerts = {
    'autoOn': false,
    'pomodoro': 5,
    'shortBreak': 1,
    'longBreak': 5
};

const autoshush = {
    'autoOn': false,
    'muteOnly': false
};

const lockRole = {
    'autoOn': false,
    'role': ''
};
  
const guildConfigs = {
'commandPrefix': 'pom!',
'sessionDefaults': sessionDefaults,
'reminderAlerts': reminderAlerts,
'autoshush': autoshush,
'volume': 5,
    'lockRole': lockRole,
'useCustomGreetings': false,
'customGreetings': 'Let\'s get this ball rolling!\nStudy time!\nYou got this!\nIt\'s study o\'clock!',
};

export default guildConfigs;