import { Url } from "../enums/urls";

const isSubscribed = async (id) => {
  const result = await fetch(`${Url.POMOMO_API}/chargebee/is-subscribed/${id}`);
  if (result.ok) {
    const response = await result.json();
    return response.subscribed;
  }

  return false;
};

const getSubscribeURL = async (token, itemPriceId) => {
  if (!token) {
    return Url.LOGIN;
  }
  const result = await fetch(
    `${Url.POMOMO_API}/chargebee/subscribe/${token}/${itemPriceId}`
  );
  const response = await result.json();

  return response.url;
};

const getPortalSessionURL = async (token) => {
  if (!token) {
    return Url.LOGIN;
  }
  const result = await fetch(`${Url.POMOMO_API}/chargebee/billing/${token}`);
  const response = await result.json();

  return response.url;
};

const redirectToCheckout = (token, itemPriceId) => {
  getSubscribeURL(token, itemPriceId).then((url) => {
    window.location.href = url;
  });
};

const redirectToPortalSession = (token) => {
  getPortalSessionURL(token).then((url) => {
    window.location.href = url;
  });
};

const ItemPriceId = {
  MONTHLY: "1_server_monthly",
  YEARLY: "1_server_yearly",
};

export {
  isSubscribed,
  redirectToPortalSession,
  redirectToCheckout,
  ItemPriceId,
};
