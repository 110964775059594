<template>
  <div>
    <figure class="logo">
      <img src="../assets/plus.png" />
    </figure>

    <div class="modal" :class="{ 'is-active': modalOn }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <article class="message is-large is-info">
          <div class="message-body" style="text-align: center">
            Use code <strong>LAUNCHPARTY</strong> to get 50% off!
          </div>
        </article>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="toggleModal"
      ></button>
    </div>

    <div class="pricing-table" style="margin-top: 20px">
      <div class="pricing-plan">
        <div class="plan-header">Plans</div>
        <div class="plan-price">
          <span class="plan-price-amount"
            ><span class="plan-price-currency">$</span>1</span
          >/mo
        </div>
        <button
          @click="subscribeMonthly"
          class="button is-warning centered"
          :class="{ 'is-loading': isLoading }"
          style="width: 190px"
        >
          Subcribe Monthly
        </button>
        <div class="divider centered" style="width: 75%; margin-left: auto">
          or
        </div>
        <div class="plan-price" style="margin-top: -5%">
          <span class="plan-price-amount"
            ><span class="plan-price-currency">$</span>10</span
          >/yr
        </div>
        <button
          @click="subscribeYearly"
          class="button is-warning centered"
          :class="{ 'is-loading': isLoading }"
          style="width: 190px"
        >
          Subcribe Annually
        </button>
      </div>
      <div class="pricing-plan">
        <div class="plan-header">Features</div>
        <div class="plan-items" style="margin-top: 20px">
          <div class="plan-item">
            Access to Pomomo+ bot with dedicated hosting
          </div>
          <div class="plan-item">24 hour sessions (no more idle timeouts)</div>
          <div class="plan-item">Multi-session limit increased to 5</div>
          <div class="plan-item">Set custom defaults for your server</div>
          <div class="plan-item">
            Start sessions with autoshush and reminder alerts on
          </div>
          <div class="plan-item">Timer updates every minute</div>
          <div class="plan-footer" />
          <div class="plan-footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { redirectToCheckout, ItemPriceId } from "../apis/chargebee";
import getAccessTokenAndType from "../helpers/accessToken";

export default {
  name: "premiumPage",
  data() {
    return {
      modalOn: false,
      isLoading: false,
    };
  },
  methods: {
    toggleModal() {
      this.modalOn = !this.modalOn;
    },
    subscribeMonthly() {
      this.clickSubscribe(ItemPriceId.MONTHLY);
    },
    subscribeYearly() {
      this.clickSubscribe(ItemPriceId.YEARLY);
    },
    clickSubscribe(itemPriceId) {
      this.isLoading = true;
      const token = getAccessTokenAndType(this.$cookies, null)[0];
      redirectToCheckout(token, itemPriceId);
    },
  },
};
</script>

<style>
@import "../../node_modules/bulma-pricingtable/dist/css/bulma-pricingtable.min.css";
</style>
