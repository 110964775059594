import firebase from 'firebase/app';
import 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCS76BV-uQYaHfHRVPEDwbRu_XWRKH1hBo",
  authDomain: "pomomo-plus.firebaseapp.com",
  projectId: "pomomo-plus",
  storageBucket: "pomomo-plus.appspot.com",
  messagingSenderId: "416733315600",
  appId: "1:416733315600:web:9b96d72d0f2a3cb47f4095",
  measurementId: "G-DF1CS38XPJ"
};

const app = firebase.initializeApp(firebaseConfig);

const db = app.firestore();

export default db;
