<template>
  <div>
    <div>
      <figure class="logo">
        <img src="../assets/plus.png" />
      </figure>

      <div
        v-if="state === 'error'"
        class="notification is-warning info-bar centered"
      >
        {{ `Error: ${errorMessage}` }} <br />
        If problem persists please email feedback.sum@gmail.com for support.
      </div>
      <div
        v-else-if="state === 'loading'"
        class="notification is-ghost info-bar centered"
      >
        Loading info...
      </div>
      <div
        v-else-if="state === 'submitted'"
        class="notification is-success info-bar centered"
      >
        Your server configurations have been saved.<br />
        Please allow for up to 1 hour for changes to be reflected on Discord.
      </div>
      <div v-else class="notification is-ghost info-bar centered">
        Thank you for subscribing! Click
        <a target="”_blank”" :href="inviteLink">here</a>
        to invite Pomomo+ to your servers.
      </div>

      <form
        @submit.prevent="updateFirebase"
        @input="fieldUpdate"
        class="form centered"
      >
        <div v-if="state === 'loading'" style="height: 50rem">
          <BeatLoader color="#363636" :size="35" class="center" />
        </div>
        <div v-else>
          <div class="title" style="text-align: center; color: #4a4a4a">
            Server Configurations
          </div>

          <div class="field rounded-border">
            <h2 v-if="managedGuilds.length > 0">Select your premium server</h2>
            <h2 v-else>You do not have manage permissions for any servers</h2>
            <div
              class="control"
              @input="guildSelect"
              style="text-align: center; margin-right: 40px"
            >
              <label
                type="radio"
                v-for="(guild, index) in managedGuilds"
                :key="guild.id"
                v-bind:style="{ cursor: 'pointer' }"
              >
                <input
                  v-if="guild.id === premiumGuildId"
                  type="radio"
                  name="guild"
                  v-bind:id="guild.id"
                  v-bind:style="{ cursor: 'pointer' }"
                  checked
                />
                <input
                  v-else-if="index === 0"
                  type="radio"
                  name="guild"
                  v-bind:id="guild.id"
                  v-bind:style="{ cursor: 'pointer' }"
                  checked
                />
                <input
                  v-else
                  type="radio"
                  name="guild"
                  v-bind:id="guild.id"
                  v-bind:style="{ cursor: 'pointer' }"
                />
                {{ guild.name }}
              </label>
            </div>
          </div>

          <div class="rounded-border">
            <div class="form-input" style="margin-top: 20px">
              <h2 style="text-align: left !important">Command Prefix</h2>
              <div class="field is-horizontal">
                <input
                  type="text"
                  id="command-prefix"
                  v-model="formData.commandPrefix"
                />
              </div>
            </div>

            <div class="form-input">
              <h2 style="text-align: left !important">Volume</h2>
              <div class="field is-horizontal">
                <input
                  type="number"
                  id="volume"
                  v-model.number="formData.volume"
                />
              </div>
            </div>

            <div class="form-input">
              <h2 style="text-align: left !important">Session Defaults</h2>
              <div class="field is-horizontal">
                <div class="field">
                  <label class="field-label is-normal">pomodoro</label>
                  <div class="control">
                    <input
                      type="number"
                      id="session-pomodoro"
                      name="session-defaults"
                      min="1"
                      max="180"
                      v-model.number="formData.sessionDefaults.pomodoro"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="field-label is-normal">short break</label>
                  <div class="control">
                    <input
                      type="number"
                      id="session-short-break"
                      name="session-defaults"
                      min="1"
                      max="180"
                      v-model.number="formData.sessionDefaults.shortBreak"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="field-label is-normal">long break</label>
                  <div class="control">
                    <input
                      type="number"
                      id="session-long-break"
                      name="session-defaults"
                      min="1"
                      max="180"
                      v-model.number="formData.sessionDefaults.longBreak"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="field-label is-normal">intervals</label>
                  <div class="control">
                    <input
                      type="number"
                      id="session-intervals"
                      name="session-defaults"
                      min="1"
                      max="180"
                      v-model.number="formData.sessionDefaults.intervals"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-input">
              <h2 style="text-align: left !important">Reminder Alerts</h2>
              <label class="switch tooltip">
                <input
                  class="switch-input"
                  type="checkbox"
                  v-model="formData.reminderAlerts.autoOn"
                />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
                <span v-if="!formData.reminderAlerts.autoOn" class="tooltiptext"
                  >Start sessions with reminder alerts automatically turned
                  on</span
                >
              </label>
              <div class="field is-horizontal">
                <div class="field">
                  <label class="field-label is-normal">pomodoro</label>
                  <div class="control">
                    <input
                      type="number"
                      id="reminder-pomodoro"
                      name="reminder-alerts"
                      min="0"
                      max="180"
                      v-model.number="formData.reminderAlerts.pomodoro"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="field-label is-normal">short break</label>
                  <div class="control">
                    <input
                      v-model.number="formData.reminderAlerts.shortBreak"
                      type="number"
                      id="reminder-short-break"
                      name="reminder-alerts"
                      min="0"
                      max="180"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="field-label is-normal">long break</label>
                  <div class="control">
                    <input
                      type="number"
                      id="reminder-long-break"
                      name="reminder-alerts"
                      min="0"
                      max="180"
                      v-model.number="formData.reminderAlerts.longBreak"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-input">
              <h2 style="text-align: left !important">Autoshush</h2>
              <label class="switch tooltip">
                <input
                  class="switch-input"
                  type="checkbox"
                  name="autoshush"
                  id="autoshush-auto-on"
                  v-model="formData.autoshush.autoOn"
                />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
                <span v-if="!formData.autoshush.autoOn" class="tooltiptext"
                  >Start sessions with "autoshush all" automatically turned
                  on</span
                >
              </label>
              <label
                v-if="formData.autoshush.autoOn"
                class="switch is-wide tooltip"
              >
                <input
                  class="switch-input"
                  type="checkbox"
                  name="autoshush"
                  id="autoshush-mute-only"
                  v-model="formData.autoshush.muteOnly"
                />
                <span
                  class="switch-label"
                  data-on="mute_only"
                  data-off="mute_only"
                ></span>
                <span class="switch-handle"></span>
                <span v-if="!formData.autoshush.muteOnly" class="tooltiptext"
                  >autoshush all mute_only</span
                >
              </label>
            </div>

            <div class="form-input">
              <h2 style="text-align: left !important">Lock Role</h2>
              <label class="switch tooltip">
                <input
                  class="switch-input"
                  type="checkbox"
                  name="lock-role"
                  id="lock-role-auto-on"
                  v-model="formData.lockRole.autoOn"
                />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
                <span v-if="!formData.lockRole.autoOn" class="tooltiptext"
                  >Start sessions with "lock [role]" automatically turned
                  on</span
                >
              </label>
              <div
                v-if="formData.lockRole.autoOn"
                class="field is-horizontal tooltip"
              >
                <div class="field">
                  <label class="field-label is-normal">role</label>
                  <div class="control">
                    <input
                      type="text"
                      id="lock-role-role"
                      name="lock-role"
                      v-model="formData.lockRole.role"
                    />
                    <span class="tooltiptext"
                      >If not a valid server role, Pomomo will default to using
                      the role of the user that started the session</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="form-input">
              <h2 style="text-align: left !important">Custom Greetings</h2>
              <label class="switch tooltip">
                <input
                  class="switch-input"
                  type="checkbox"
                  name="custom-greetings"
                  id="use-custom-greetings"
                  v-model="formData.useCustomGreetings"
                />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
              </label>
              <div
                v-if="formData.useCustomGreetings"
                class="field is-horizontal tooltip"
              >
                <div class="field">
                  <label class="field-label is-normal"></label>
                  <div class="control">
                    <textarea
                      rows=10
                      cols=50
                      id="custom-greetings-text"
                      name="custom-greetings"
                      v-model="formData.customGreetings"
                    />
                    <span class="tooltiptext"
                      >Add phrases separated with a new line to use as custom greeting</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="text-align: center; margin-top: 25px">
            <button
              v-if="state === 'modified' && managedGuilds.length > 0"
              type="submit"
              class="button is-medium is-rounded"
            >
              Save
            </button>
            <button
              v-else-if="state === 'updating'"
              type="submit"
              class="button is-loading is-medium is-rounded"
            >
              Save
            </button>
            <button
              v-else
              type="submit"
              class="button is-medium is-rounded"
              disabled
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import db from "../database";
import { Url, RedirectParam } from "../enums/urls";
import hasPermission from "../helpers/bitwise";
import { getGuildsForCurrentUser } from "../apis/discord";
import guildConfigs from "../models/GuildConfigData";
import { isSubscribed } from "../apis/chargebee";
import getAccessTokenAndType from "../helpers/accessToken";
import { BeatLoader } from "@saeris/vue-spinners";
import Cookie from "../enums/cookies";

const DataState = {
  LOADING: "loading",
  SYNCED: "synced",
  MODIFIED: "modified",
  UPDATING: "updating",
  SUBMITTED: "submitted",
  ERROR: "error",
};

export default {
  name: "serverConfigForm",
  props: {
    userData: Object,
  },
  components: {
    BeatLoader,
  },
  data() {
    return {
      state: DataState.LOADING,
      formData: {},
      discordUserId: "",
      premiumGuildId: "",
      errorMessage: "",
      managedGuilds: [],
      inviteLink: Url.PREMIUM_INVITE,
    };
  },

  created: async function () {
    if (this.$cookies.get(Cookie.USER_ID)) {
      this.discordUserId = this.$cookies.get(Cookie.USER_ID);
    } else if (this.userData) {
      this.discordUserId = this.userData.id;
    } else {
      window.location.href = Url.LOGIN + RedirectParam.CONFIG;
      return;
    }

    const [token, type] = getAccessTokenAndType(this.$cookies, null);

    try {
      const MANAGED_GUILD_PERMISSION = 5;
      const subscriptionsDocData = (
        await db.doc(`subscriptions/${this.discordUserId}`).get()
      ).data();
      this.managedGuilds = (await getGuildsForCurrentUser(token, type)).filter(
        (g) => hasPermission(g.permissions, MANAGED_GUILD_PERMISSION)
      );
      if (subscriptionsDocData) {
        this.premiumGuildId = subscriptionsDocData.premiumGuildId;
        const premiumGuildsDocData = (
          await db
            .doc(`premiumGuilds/${subscriptionsDocData.premiumGuildId}`)
            .get()
        ).data();
        this.formData = premiumGuildsDocData.guildConfigs ?? guildConfigs;
      } else {
        this.premiumGuildId =
          this.managedGuilds.length > 0 ? this.managedGuilds[0].id : "";
        this.formData = guildConfigs;
      }
    } catch (e) {
      this.state = DataState.ERROR;
      return;
    }

    setTimeout(() => (this.state = DataState.SYNCED), 2000);
  },

  methods: {
    async updateFirebase() {
      if (
        !this.premiumGuildId ||
        !this.discordUserId ||
        this.formData.length === 0
      ) {
        this.errorMessage = "Missing essential data";
        this.state = DataState.ERROR;
        document.body.scrollIntoView({ behavior: "smooth" });
        return;
      }

      const subscribed = await isSubscribed(this.discordUserId);
      if (!subscribed) {
        this.errorMessage = "You are not subscribed.";
        this.state = DataState.ERROR;
        document.body.scrollIntoView({ behavior: "smooth" });
        return;
      }

      try {
        this.state = DataState.UPDATING;

        const premiumGuildsDocData = (
          await db.doc(`premiumGuilds/${this.premiumGuildId}`).get()
        ).data();
        const subscriptionsDocData = (
          await db.doc(`subscriptions/${this.discordUserId}`).get()
        ).data();

        if (
          premiumGuildsDocData &&
          premiumGuildsDocData.subscriptionId !== this.discordUserId
        ) {
          throw "This server is already associated with a subscription.";
        }

        if (
          subscriptionsDocData &&
          subscriptionsDocData.premiumGuildId !== this.premiumGuildId
        ) {
          await db
            .doc(`premiumGuilds/${subscriptionsDocData.premiumGuildId}`)
            .delete();
          window.open(this.inviteLink, "_blank");
        }

        console.log(this.discordUserId, guildConfigs);

        await Promise.all([
          await db.doc(`subscriptions/${this.discordUserId}`).set({
            premiumGuildId: this.premiumGuildId,
          }),
          await db.doc(`premiumGuilds/${this.premiumGuildId}`).set({
            guildConfigs: this.formData,
            subscriptionId: this.discordUserId,
          }),
        ]);

        this.state = DataState.SUBMITTED;
      } catch (error) {
        this.errorMessage = JSON.stringify(error.code).slice(1, -1);
        this.state = DataState.ERROR;
      } finally {
        document.body.scrollIntoView({ behavior: "smooth" });
      }
    },
    async fieldUpdate() {
      this.state = DataState.MODIFIED;
    },
    async guildSelect(e) {
      this.premiumGuildId = e.target.id;
    },
  },
};
</script>

<style>
@import "../style/toggle.css";
@import "../style/tooltip.css";

input[type="radio"] {
  margin: 10px 0 10px 40px;
}
.form {
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 5vh 5vw 4vh;
  /* margin: auto; */
  width: 50rem;
}
.info-bar {
  width: 50rem;
  /* margin: auto; */
  text-align: center;
}
.rounded-border {
  border: 2px solid #5f5f5f;
  border-radius: 5px;
  padding: 15px;
}
.form-input {
  margin-bottom: 20px;
  margin-left: 20px;
}
</style>