import db from "../database";
// import { collection, getDocs } from "firebase/firestore";

const authorizedFetch = async (url, token, tokenType) => {
  return await fetch(url, {
    headers: {
      authorization: `${tokenType} ${token}`,
    },
  });
};

const getGuildsForCurrentUser = async (token, tokenType) => {
  const guildsResponse = await authorizedFetch(
    "https://discord.com/api/users/@me/guilds",
    token,
    tokenType
  );

  if (!guildsResponse.ok) {
    console.log(guildsResponse.statusText);
    return;
  }

  return await guildsResponse.json();
};

const getCurrentUser = async (token, type) => {
  if (!token || !type) return;

  const userResponse = await authorizedFetch(
    "https://discord.com/api/users/@me",
    token,
    type
  );

  if (!userResponse.ok) {
    console.log(userResponse.statusText);
    return;
  }

  return await userResponse.json();
};

const getServerCount = async () => {
  let count = 0;

  const bots = await db.collection("bots").get();
  bots.forEach((b) => {
    count += b.data().serverCount;
  });

  if (count == 0) {
    return 20000;
  }
  return count;
};

export { getCurrentUser, getGuildsForCurrentUser, getServerCount };
