<template>
  <div class="container" style="max-width: 810px">
    <div
      class="notification is-link is-light has-text-centered"
      style="margin-top: 1rem"
    >
      Use code <strong>LAUNCHPARTY</strong> to get 50% off Premium!
    </div>
    <div
      class="container has-text-centered"
      style="padding-top: 1rem; padding-bottom: 5rem"
    >
      <p class="title is-1 has-text-weight-bold has-text-dark is-size-3-mobile">
        Your new study buddy.
      </p>
      <div class="subtitle is-5 has-text-grey is-size-6-mobile">
        Join over
        <strong class="has-text-info is-size-4 is-size-5-mobile">{{
          this.serverCount.toLocaleString()
        }}</strong>
        servers studying with Pomomo
      </div>
      <a
        class="button is-warning is-large is-rounded is-medium-mobile"
        :href="inviteLink"
        target="_blank"
      >
        Add to Discord
      </a>
    </div>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="content">
          <div class="title">Simple.</div>
          <div class="subtitle">
            Pomomo is lightweight and intuitive. Add the bot to your server and
            get straight to work!
          </div>
        </div>
      </div>
      <div class="column image img340x">
        <img src="../assets/pomojis/simple.png" />
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column image img340x">
        <img src="../assets/pomojis/mashup.png" />
      </div>
      <div class="column">
        <div class="content">
          <div class="title">Effective.</div>
          <div class="subtitle">
            Use the proven Pomodoro technique to maximize productivity and
            minimize burnout.
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="content">
          <div class="title">Simple.</div>
          <div class="subtitle">
            Actually get stuff done while hanging out with your friends and
            Discord community.
          </div>
        </div>
      </div>
      <div class="column image img340x">
        <img src="../assets/pomojis/friends.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { getServerCount } from "../apis/discord";
import { Url } from "../enums/urls";

export default {
  name: "HomePage",
  data() {
    return {
      serverCount: 20000,
      inviteLink: Url.BOT_INVITE,
    };
  },
  async created() {
    this.serverCount = await getServerCount();
  },
};
</script>

<style>
.img340x {
  max-height: 340px;
  max-width: 340px;
}
@media only screen and (max-width: 600px) {
  .image {
    display: none;
  }
}
</style>