import Vue from "vue";
import App from "./App.vue";
import { firestorePlugin } from "vuefire";
import router from "./router";
import VueCookies from "vue-cookies";
import { VueSpinners } from "@saeris/vue-spinners";

Vue.use(firestorePlugin);
Vue.use(VueCookies);
Vue.use(VueSpinners);

const app = new Vue({
  el: "#app",
  router: router,
  render: (h) => h(App),
}).$mount("#app");

export default app;
