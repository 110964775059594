const EncodedDomain = {
  DISCORD_OAUTH:
    "https://discord.com/api/oauth2/authorize?client_id=821952460909445130" +
    "&response_type=token&scope=identify%20email%20guilds&redirect_uri=",
  BASE: "https%3A%2F%2Fpomomo.us",
  // BASE: "http%3A%2F%2Flocalhost%3A8080",
};

const RedirectParam = {
  CONFIG: "%2F%23%2Fconfig",
  PREMIUM: "%2F%23%2Fpremium",
};

const Url = {
  POMOMO_API: "https://pomomo-api.herokuapp.com",
  // POMOMO_API: "http://127.0.0.1:5000/",
  // POMOMO_SITE: "pomomo.us",
  // POMOMO_SITE: "localhost:8080",
  LOGIN: EncodedDomain.DISCORD_OAUTH + EncodedDomain.BASE,
  BOT_INVITE:
    "https://discord.com/login?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D821952460909445130%26permissions%3D15739904%26scope%3Dbot",
  PREMIUM_INVITE:
    "https://discord.com/api/oauth2/authorize?client_id=833779766515335219&permissions=15804480&scope=bot",
  SUPPORT_SERVER: "https://discord.gg/Aghy78wcFr",
};

export { Url, RedirectParam };
