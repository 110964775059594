<template>
  <nav
    class="navbar is-white is-fixed-top is- is-spaced"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        <img src="../assets/pomomologo.png" />
      </router-link>
      <a
        role="button"
        :class="
          navbarHamburgerIsActive ? 'navbar-burger is-active' : 'navbar-burger'
        "
        aria-label="menu"
        aria-expanded="false"
        @click="toggleNavbarHamburger"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      :class="navbarHamburgerIsActive ? 'navbar-menu is-active' : 'navbar-menu'"
    >
      <div class="navbar-end">
        <router-link v-if="this.userData && this.userData.isSubscribed" class="navbar-item" to="config" id="premium"
          >Premium</router-link
        >
        <router-link v-else class="navbar-item" to="premium" id="premium"
          >Premium</router-link
        >
        <a class="navbar-item" :href="invite" target="_blank">Add to Discord</a>
        <a class="navbar-item" :href="supportServer" target="_blank">Support</a>
        <div
          v-if="this.userData"
          class="navbar-item has-dropdown is-hoverable"
          id="login"
        >
          <a class="navbar-item">
            <img class="avatar" :src="avatar" />
          </a>
          <div class="navbar-dropdown is-right">
            <div v-if="this.userData && this.userData.isSubscribed">
              <router-link to="config" class="navbar-item">Servers</router-link>
              <a @click="goToBilling" class="navbar-item">Billing</a>
              <hr class="navbar-divider" />
            </div>
            <a @click="logOut" class="navbar-item">Log out</a>
          </div>
        </div>
        <div v-else class="navbar-item" id="login">
          <a class="button is-warning" id="loginBtn" :href="loginUrl">
            <strong>Log in</strong>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Url } from "../enums/urls";
import Cookie from "../enums/cookies";
import { redirectToPortalSession } from "../apis/chargebee";

export default {
  name: "BasicNavbar",
  props: {
    home: Function,
    userData: Object,
  },
  data() {
    return {
      loginUrl: Url.LOGIN,
      invite: Url.BOT_INVITE,
      supportServer: Url.SUPPORT_SERVER,
      avatar: null,
      navbarHamburgerIsActive: false,
    };
  },
  methods: {
    logOut() {
      this.$cookies.remove(Cookie.ACCESS_TOKEN);
      this.$cookies.remove(Cookie.TOKEN_TYPE);
      this.$emit("logOut");
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
    goToBilling() {
      this.$emit("goToBilling");
      redirectToPortalSession(this.$cookies.get(Cookie.ACCESS_TOKEN));
    },
    toggleNavbarHamburger() {
      this.navbarHamburgerIsActive = !this.navbarHamburgerIsActive;
    },
  },
  async updated() {
    if (!this.avatar) {
      if (this.userData.avatar) {
        this.avatar = `https://cdn.discordapp.com/avatars/${this.userData.id}/${this.userData.avatar}.png`;
      } else {
        this.avatar = require("../assets/discord-avatar.png");
      }
    }
  },
};
</script>

<style>
.avatar {
  border-radius: 50%;
}
@media only screen and (max-width: 600px) {
  #login,
  #premium {
    display: none;
  }
}
</style>