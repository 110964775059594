import Vue from "vue";
import Router from "vue-router";
import HomePage from "../components/HomePage";
import PremiumPage from "../components/PremiumPage";
import ServerConfigForm from "../components/ServerConfigForm";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/premium", name: "premium", component: PremiumPage },
    { path: "/config", name: "config", component: ServerConfigForm },
    { path: "/:fragment?", name: "home", component: HomePage },
  ],
});
